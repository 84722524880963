// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.


import { useEffect, useRef, useState } from "react";
import { PlansActiveTasksPieChart } from "./PlansActiveTasksPieChart";
import { PlanStackedPlans } from "./PlanStackedPlans";
import { GanttPlan, GanttTask, Person } from "../../../../ganttmods/publictypes";
import { TaskStatus, getChartColors, getDurationInDaysExcludingWeekends, getFromLocalCache, getTaskStatus, log, saveToLocalCache } from "../../../helper";
import { PeopleTasks } from "./PeopleTasks";
import { PepleGraph } from "../../PeopleGraph";
import { TeamsFx } from "@microsoft/teamsfx";
import { UnassignedTasks } from "./UnassignedTasks";
import { Button, Dropdown, DropdownProps, Flex, Loader } from "@fluentui/react-northstar";

import { Line } from '@nivo/line';
import { Sunburst } from "@nivo/sunburst";
import { MeasureTasks } from "./PortfolioDashboard";

  interface SunNode {
    name: string;
    
    loc?: number;
    children?: SunNode[];
  }
  
  
export function PlanTasksSunburst(props: {plan:GanttPlan, people:Person[], width:number,  height:number , teamsfx?: TeamsFx, measureTasks:MeasureTasks}) 
{
  const [sunburstdata, setSunburstData] = useState<SunNode>({name: 'nivo',  children: []});
  const { teamsfx } = {
    teamsfx: undefined,
    ...props,
    };
  useEffect(()=>{
    log('PlanDetails', props.plan, props.people);
    let newsunburstdata:SunNode = {name: 'nivo',  children: []};
    
    if (props.plan.tasks != undefined && props.plan.tasks.length != 0 && props.people.length != 0) 
    {
      let sunburstnotstarted:SunNode = {name: 'Not Started',  children: []};
      for (let i = 0; i < props.plan.tasks.length; i++) 
      {
        let taskstatus:TaskStatus = getTaskStatus(props.plan.tasks[i].progress, props.plan.tasks[i].end);
        const task = props.plan.tasks[i];
        if (taskstatus == TaskStatus.NotStarted) 
        {
          sunburstnotstarted.children?.push({name: task.name, loc: getMeasureOfTasks(props.plan.tasks[i].start, props.plan.tasks[i].end)});
        }
      }
      newsunburstdata.children?.push(sunburstnotstarted);
      let sunburstinprogress:SunNode = {name: 'In Progress', children: []};
      for (let i = 0; i < props.plan.tasks.length; i++) 
      {
        let taskstatus:TaskStatus = getTaskStatus(props.plan.tasks[i].progress, props.plan.tasks[i].end);
        const task = props.plan.tasks[i];
        if (taskstatus == TaskStatus.InProgress) 
        {
          sunburstinprogress.children?.push({name: task.name,  loc: getMeasureOfTasks(props.plan.tasks[i].start, props.plan.tasks[i].end)});
        }
      }
      newsunburstdata.children?.push(sunburstinprogress);
      
      let sunburstLate:SunNode = {name: 'Late',  children: []};
      for (let i = 0; i < props.plan.tasks.length; i++) 
      {
        let taskstatus:TaskStatus = getTaskStatus(props.plan.tasks[i].progress, props.plan.tasks[i].end);
        const task = props.plan.tasks[i];
        if (taskstatus == TaskStatus.Late) 
        {
          sunburstLate.children?.push({name: task.name,  loc: getMeasureOfTasks(props.plan.tasks[i].start, props.plan.tasks[i].end)});
        }
      }
      newsunburstdata.children?.push(sunburstLate);

      let sunburstcompleted:SunNode = {name: 'Completed',  children: []};
      for (let i = 0; i < props.plan.tasks.length; i++) 
      {
        let taskstatus:TaskStatus = getTaskStatus(props.plan.tasks[i].progress, props.plan.tasks[i].end);
        const task = props.plan.tasks[i];
        if (taskstatus == TaskStatus.Completed) 
        {
          sunburstcompleted.children?.push({name: task.name,  loc: getMeasureOfTasks(props.plan.tasks[i].start, props.plan.tasks[i].end)});
        }
      }
      newsunburstdata.children?.push(sunburstcompleted);

    }
    setSunburstData(newsunburstdata);
  }, [props.plan, props.people]);
  const getMeasureOfTasks = (startd:Date, endd:Date):number => {
    if (props.measureTasks == MeasureTasks.Duration)
    {
      return getDurationInDaysExcludingWeekends(startd, endd);
    } else
    {
      return 1;
    }
  }
  
  

  return (
    <>
     <Sunburst
      width={props.width}
      height={props.height}
      data={sunburstdata}
      margin={{ top: 10, right: 60, bottom: 10, left: 20 }}
      id="name"
      value="loc"
      cornerRadius={2}
      borderColor="#eeeeee"
      borderWidth={4}
      colors={getChartColors()}
      childColor={{
          from: 'color',
          modifiers: [
              [
                  'brighter',
                  0.2
              ]
          ]
      }}
      enableArcLabels={true}
      arcLabelsSkipAngle={15}
      arcLabelsTextColor={{
          from: 'color',
          modifiers: [
              [
                  'darker',
                  1.4
              ]
          ]
      }}
      arcLabel={(e)=>{return e.data.name}}
      tooltip={({ id, value, color }) => (
          <div style={{
              padding: 12,
              color,
              background: '#000000'
            }}>
            <strong style={{ color:'white', background: '#000000' }}>
                {id}: {Math.ceil(value)} {props.measureTasks == MeasureTasks.Duration?'day(s)':'task(s)'}
            </strong>
          </div>
      )}
    />
    </>
  );
}
