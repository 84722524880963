// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.


import { Bar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { GanttPlan, Person } from "../../../../ganttmods/publictypes";
import { TaskStatus, getChartColors, getDurationInDaysExcludingWeekends, getTaskStatus, log, nivodarktheme } from "../../../helper";
import { MeasureTasks } from "./PortfolioDashboard";
import { TeamsFx } from "@microsoft/teamsfx";
import { useContext } from 'react';
import { TeamsFxContext } from "../../../Context";

export function PlanStackedPlans(props: {dashboardplans:GanttPlan[], people:Person[], width:number,  height:number, planselected:((plan:GanttPlan)=>void), measureTasks:MeasureTasks, teamsfx?: TeamsFx}) 
{
    const { teamsfx } = {
        teamsfx: undefined,
        ...props,
      };
      const { themeString } = useContext(TeamsFxContext);
    const [plansdata, setPlansdata] = useState<{Project:string, Completed:number, InProgress:number, NotStarted:number, Late:number}[]>([]);
    useEffect(()=>{
        if (props.dashboardplans.length == 0 || props.people.length == 0)
        {
            return;
        }
        let today = new Date();
        let newplansdata:any[] = [];
        for (let i = 0; i < props.dashboardplans.length; i++)
        {
            let plan = props.dashboardplans[i];
            if (plan.tasks?.length != undefined && plan.tasks.length > 0)
            {
                let completedtasks = plan.tasks?.filter((val)=>{return getTaskStatus(val.progress, val.end) == TaskStatus.Completed}).length;
                let latetasks = plan.tasks?.filter((val)=>
                    {
                        return getTaskStatus(val.progress, val.end) == TaskStatus.Late
                    }).length;
                let notstartedtasks = plan.tasks?.filter((val)=>{return getTaskStatus(val.progress, val.end) == TaskStatus.NotStarted}).length;
                let inprogresstasks = plan.tasks?.filter((val)=>
                    {
                        return val.progress > 0 && getTaskStatus(val.progress, val.end) == TaskStatus.InProgress
                    }).length;
                newplansdata.push({Project:plan.planName, Completed:completedtasks, InProgress:inprogresstasks, NotStarted:notstartedtasks, Late:latetasks});
            }
            
        }
        log('Dashboard PlanStackedPlans data', newplansdata, props.dashboardplans);
        newplansdata = newplansdata.sort((a,b)=>{
            return (a.Completed+a.InProgress+a.NotStarted+a.Late)<(b.Completed+b.InProgress+b.NotStarted+b.Late)?-1:1
        });
        setPlansdata(newplansdata);
    }, [props.dashboardplans, props.people]);
    const getMeasureOfTasks = (startd:Date, endd:Date):number => 
    {
        if (props.measureTasks == MeasureTasks.Duration)
        {
            return getDurationInDaysExcludingWeekends(startd, endd);
        } else
        {
            return 1;
        }
    }
  return (
    <>
    <Bar
        data={plansdata}
        width={props.width}
        height={props.height}
        keys={[
            'NotStarted',
            'InProgress',
            'Late',
            'Completed'
        ]}
        layout="horizontal"
        indexBy="Project"
        margin={{ top: 50, right: 50, bottom: 50, left: 200 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={getChartColors()}
        theme={themeString == "default"?undefined:nivodarktheme}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
            ,
            {
                id: 'completed',
                type: 'patternLines',
                background: '#97e3d5',
                color: '#61cdbb',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            },
            {
                id: 'notstarted',
                type: 'patternLines',
                background: '#e5f5e0',
                color: '#e5f5e0',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'NotStartedOld'
                },
                id: 'notstarted'
            },
            {
                match: {
                    id: 'CompletedOld'
                },
                id: 'completed'
            }
        ]}
        
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'No. Tasks',
            legendPosition: 'middle',
            legendOffset: 32,
            truncateTickAt: 0
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'top-left',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -20,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel="Tasks per plan"
        borderRadius={4}
        onClick={(data:any)=>
            {
                let planselected = props.dashboardplans.find((val)=>{return val.planName == data.data.Project}) as GanttPlan;
                if (planselected != undefined)
                {
                    props.planselected(planselected);
                }
            }}
        onMouseEnter={(data, event) => {
            (event.target as HTMLElement).style.cursor = 'pointer';
        }}
            onMouseLeave={(data, event) => {
            (event.target as HTMLElement).style.cursor = 'default';
       }}
       tooltip={({ id, value, color }) => (
            <div
            style={{
                padding: '12px',
                color: themeString == "default"?"#000":"#fff",
                background: themeString == "default"?"rgb(238, 238, 238)":"rgb(45, 44, 44)",
                borderRadius: '5px',
                boxShadow: themeString == "default"?"0 4px 8px rgba(0, 0, 0, 0.2)":"0 4px 8px #000000, 0px 2px 2px #333"
            }}
            >
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <div
                    style={{
                    width: '12px',
                    height: '12px',
                    background: color,
                    marginRight: '10px',
                    }}
                />
                    <span>
                        {value} task(s) are{' '}
                        {(id as string)
                        .replace('InProgress', 'In Progress')
                        .replace('NotStarted', 'Not Started')
                        .replace('Late', 'Late')
                        .replace('Completed', 'Completed')}
                    </span>
                </div>

            </div>
        )}
    />
    </>
  );
}
