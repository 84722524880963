import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import HeatmapContainer, { ViewMode } from './HeatmapContainer';
import { GanttPlan, Person } from '../../ganttmods/publictypes';
import { TeamsFx } from "@microsoft/teamsfx";
import { TeamsFxContext } from '../Context';
import { useContext } from 'react';
import { log } from '../helper';



function HeatmapChart(props: {dashboardplans:GanttPlan[], people:Person[], width:number,  height:number, teamsfx?: TeamsFx}) {
  

    const { teamsfx } = {
        teamsfx: undefined,
        ...props,
      };
    const { themeString } = useContext(TeamsFxContext);
    useEffect(() => {
      log('HeatmapChart people and plans', props.dashboardplans, props.people);
    }, [props.dashboardplans, props.people]);
  return (
    <>
       
      {/*<HeatmapContainer ganttPlans={generateData() as GanttPlan[]} view={ViewMode.Month} theme='light' height={600} width={600} />*/}
      <HeatmapContainer ganttPlans={props.dashboardplans} people={props.people} view={ViewMode.Month} theme={(themeString == "default"?"light":"dark")} height={props.height} width={props.width} />
      
      {/*<HeatmapContainer ganttPlans={generateData() as GanttPlan[]} view={ViewMode.Day} />*/}
    </>
  );
}

export default HeatmapChart;
