interface HeaderProps {
    xLabels: string[];
    secondHeaderLabels: string[];
  }
  
  const HeaderComponent: React.FC<HeaderProps> = ({ xLabels, secondHeaderLabels }) => {
    return (
      <>
        {/* Empty Corner for Second Header */}
        <div className="heatmap-empty-corner"></div>
        {/* Second Header Labels */}
        {secondHeaderLabels.map((label, idx) => {
          const shouldHide = (idx > 0 && label === secondHeaderLabels[idx - 1] ) || label === xLabels[idx];
          return (
            <div
              key={idx}
              className="heatmap-second-header-label"
              style={{zIndex: shouldHide?0:9}}
            >
              {shouldHide?"":label}
            </div>
          );
        })}
        {/* Empty Corner for X Labels */}
        <div className="heatmap-empty-corner"></div>
        {/* Sticky X Labels */}
        {xLabels.map((label, idx) => (
          <div key={idx} className="heatmap-x-label">
            {label}
          </div>
        ))}
      </>
    );
  };
  
  export default HeaderComponent;
  