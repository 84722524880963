import React, { useEffect, useRef } from "react";
import './HeatmapComponent.css';
import HeaderComponent from "./HeaderComponent";

interface HeatmapComponentProps {
  xLabels: string[];
  xLabelsLong: string[];
  secondHeaderLabels: string[];
  yLabels: string[];
  data: number[][];
  maxColor?: string;
  minColor?: string;
  maxIntensity?: number;
  maxValue: number;
  width: string;
  height: string;
  scrollto: number | undefined;
}



const HeatmapComponent: React.FC<HeatmapComponentProps> = ({
  xLabels,
  xLabelsLong,
  secondHeaderLabels,
  yLabels,
  data,
  maxColor = "222, 150, 157", // Default color (pinkish-red)
  minColor = "138, 209, 191", // Default color (light green)
  maxIntensity = 1,
  maxValue,
  width,
  height,
  scrollto
}) => {
  // Convert RGB color strings to arrays of numbers
  const parseRGB = (rgb: string) => rgb.split(",").map(Number);



  // Find minimum non-zero value and maximum value
  const flatData = data.flat();
  const nonZeroValues = flatData.filter((val) => val > 0);

  const scrollableDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollableDivRef.current && scrollto !== undefined) 
    {
      scrollableDivRef.current.scrollLeft = scrollto;
    }
  }, [scrollto]);
  
  return (
    <div  ref={scrollableDivRef} className="heatmap-container" style={{ width, height }}>
      <div className="heatmap-grid" style={{ gridTemplateColumns: `200px repeat(${xLabels.length}, 40px)` }}>
        <HeaderComponent xLabels={xLabels} secondHeaderLabels={secondHeaderLabels} />
        {/* Y Labels and Heatmap Cells */}
        {yLabels.map((label, yIdx) => (
          <React.Fragment key={yIdx}>
            {/* Sticky Y Label */}
            <div className="heatmap-y-label">
              <div className="heatmap-y-label-center">{label}</div>
            </div>
            {/* Heatmap Row */}
            {data[yIdx].map((value, xIdx) => (
              <div
                key={xIdx}
                className="heatmap-cell"
                style={{
                  background: value > 0
                    ? label == "Unassigned Tasks" ? `rgb(${minColor})`: `color-mix(in hsl, rgb(${minColor}) ${100-Math.round((value/maxValue)*100)}%, rgb(${maxColor})  ${Math.round((value/maxValue)*100)}%)`
                    : "inherit",
                  color: value === 0 ? "transparent" : "#444",
                }}
              >
                {value} <div className="heatmap-cell-tooltip">{label} <br/> Booked to {value} task(s) <br /> {xLabelsLong[xIdx%xLabels.length]}<br/><div className="tooltipguide">Color based on number of tasks</div></div> 
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default HeatmapComponent;
