
import { Bar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { GanttPlan, Person } from "../../../../ganttmods/publictypes";
import { TaskStatus, getChartColors, getDurationInDaysExcludingWeekends, getTaskStatus, log } from "../../../helper";
import { MeasureTasks } from "./PortfolioDashboard";


export function UnassignedTasks(props: {dashboardplans:GanttPlan[],  people:Person[], width:number,  height:number, measureTasks:MeasureTasks }) 
{
    const [peopledata, setPeopledata] = useState<{Project:string, Completed:number, InProgress:number, NotStarted:number, Late:number}[]>([]);
    useEffect(()=>{
        if (props.dashboardplans.length == 0 || props.people.length == 0)
        {
            return;
        }
        let today = new Date();
        let newassndata:any[] = [];
        let unknownindex = 1;
        let unassignedtaskcompleted = 0;
        let unassignedtaskInProgress = 0;
        let unassignedtaskNotStarted = 0;
        let unassignedtaskLate = 0;
        for (let i = 0; i < props.dashboardplans.length; i++)
        {
            let plan = props.dashboardplans[i];

            

            if (plan.tasks?.length != undefined && plan.tasks.length > 0)
            {
                for (let t=0; t < plan.tasks?.length; t++)
                {
                    
                    let taskstatus:TaskStatus = getTaskStatus(plan.tasks[t].progress, plan.tasks[t].end);
                    
                    if (plan.tasks[t].assignments == undefined || plan.tasks[t].assignments.length == 0)
                    {
                        if (taskstatus == TaskStatus.Completed)
                        {
                            unassignedtaskcompleted += getMeasureOfTasks(plan.tasks[t].start, plan.tasks[t].end);;
                        }
                        if (taskstatus == TaskStatus.InProgress)
                        {
                            unassignedtaskInProgress += getMeasureOfTasks(plan.tasks[t].start, plan.tasks[t].end);;
                        }
                        if (taskstatus == TaskStatus.NotStarted)
                        {
                            unassignedtaskNotStarted += getMeasureOfTasks(plan.tasks[t].start, plan.tasks[t].end);;
                        }
                        if (taskstatus == TaskStatus.Late)
                        {
                            unassignedtaskLate += getMeasureOfTasks(plan.tasks[t].start, plan.tasks[t].end);
                        }
                    }
                }                
            }
        }
        newassndata.push({Person:"Unassigned", Completed:unassignedtaskcompleted, InProgress:unassignedtaskInProgress, NotStarted:unassignedtaskNotStarted, Late:unassignedtaskLate, Id:"364daba0-a57e-4118-85c2-e7acf78c3150"});
        log('dashboard data', newassndata, props.dashboardplans);
        setPeopledata(newassndata);
    }, [props.dashboardplans, props.people]);

    const getMeasureOfTasks = (startd:Date, endd:Date):number => 
    {
        if (props.measureTasks == MeasureTasks.Duration)
        {
            return getDurationInDaysExcludingWeekends(startd, endd);
        } else
        {
            return 1;
        }
    }

  return (
    <>
    <Bar
        data={peopledata}
        width={props.width}
        height={props.height}
        keys={[
            'NotStarted',
            'InProgress',
            'Late',
            'Completed'
        ]}
        layout="vertical"
        indexBy="Person"
        margin={{ top: 70, right: 40, bottom: 100, left: 40 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={getChartColors()}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
            ,
            {
                id: 'completed',
                type: 'patternLines',
                background: '#97e3d5',
                color: '#61cdbb',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            },
            {
                id: 'notstarted',
                type: 'patternLines',
                background: '#e5f5e0',
                color: '#e5f5e0',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'NotStartedOld'
                },
                id: 'notstarted'
            },
            {
                match: {
                    id: 'CompletedOld'
                },
                id: 'completed'
            }
        ]}
       
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
            truncateTickAt: 0
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
        borderRadius={4}
        
    />
    </>
  );
}
