import React from 'react';

import './HeatmapDropdown.css';
import { ViewMode } from './HeatmapContainer';

interface DropdownProps {
  view: ViewMode;
  onViewChange: (newView: ViewMode) => void;
  width: number;
}

const HeatmapDropdown: React.FC<DropdownProps> = ({ view, onViewChange, width }) => {
  return (
    <div className="view-container" style={{width:(width) + 'px'}}>
        <div className="view-dropdown">
          <select
          className='view-dropdown-select'
            id="viewMode"
            value={view}
            onChange={(e) => onViewChange(e.target.value as ViewMode)}
          >
            <option value={ViewMode.Year}>Year</option>
            <option value={ViewMode.Month}>Month</option>
            {/*<option value={ViewMode.Week}>Week</option>*/}
            <option value={ViewMode.Day}>Day</option>
          </select>
        </div>
      </div>
  );
};

export default HeatmapDropdown;
