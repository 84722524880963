
import { Bar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { GanttPlan, GanttTask, Person } from "../../../../ganttmods/publictypes";
import { TaskStatus, getChartColors, getDurationInDaysExcludingWeekends, getTaskStatus, lightenHexColor, log } from "../../../helper";
import { MeasureTasks } from "./PortfolioDashboard";


export function PeopleTasks(props: {dashboardplans:GanttPlan[],  people:Person[], width:number,  height:number, measureTasks:MeasureTasks }) 
{
    const [peopledata, setPeopledata] = useState<{Id:string, Person:string, Completed:number, InProgress:number, NotStarted:number, Late:number, alsoassignedto:any}[]>([]);
    const [labelcolors, setLabelcolors] = useState<string[]>([]);
    useEffect(()=>{
        let colors = getChartColors();
        const peoplechartcolors: string[] = [];

        for (const color of colors) {
            peoplechartcolors.push(color); // Add the original color
            peoplechartcolors.push(lightenHexColor(color, 40)); // Add the duplicate color
        }
        setLabelcolors(peoplechartcolors);

        if (props.dashboardplans.length == 0 || props.people.length == 0)
        {
            return;
        }
        let today = new Date();
        let newassndata:any[] = [];
        let unknownindex = 1;
        
        for (let i = 0; i < props.dashboardplans.length; i++)
        {
            let plan = props.dashboardplans[i];

            

            if (plan.tasks?.length != undefined && plan.tasks.length > 0)
            {
                for (let t=0; t < plan.tasks?.length; t++)
                {
                    
                    let taskstatus:TaskStatus = getTaskStatus(plan.tasks[t].progress, plan.tasks[t].end);
                    for (let a=0;a<plan.tasks[t].assignments?.length;a++)
                    {
                        let assignment = plan.tasks[t].assignments[a];
                        
                        const index = newassndata.findIndex(assn => assn.Id == assignment.id);
                        
                        if (index !== -1) 
                        {
                            if (a >= 1)
                            {
                                newassndata[index].alsoassignedto.push((plan.tasks[t] as any).title);
                            }
                            if (taskstatus == TaskStatus.Completed && a==0)
                            {
                                newassndata[index].Completed += getMeasureOfTasks(plan.tasks[t].start, plan.tasks[t].end);
                            } else
                            {
                                newassndata[index].CompletedAlreadyassigned += getMeasureOfTasks(plan.tasks[t].start, plan.tasks[t].end);
                            }
                            if (taskstatus == TaskStatus.InProgress && a==0)
                            {
                                newassndata[index].InProgress += getMeasureOfTasks(plan.tasks[t].start, plan.tasks[t].end);
                            } else
                            {
                                newassndata[index].InProgressAlreadyassigned++;
                            }
                            if (taskstatus == TaskStatus.NotStarted && a==0)
                            {
                                newassndata[index].NotStarted += getMeasureOfTasks(plan.tasks[t].start, plan.tasks[t].end);
                            } else
                            {
                                newassndata[index].NotStartedAlreadyassigned += getMeasureOfTasks(plan.tasks[t].start, plan.tasks[t].end);
                            }
                            if (taskstatus == TaskStatus.Late && a==0)
                            {
                                newassndata[index].Late += getMeasureOfTasks(plan.tasks[t].start, plan.tasks[t].end);
                            } else
                            {
                                newassndata[index].LateAlreadyassigned += getMeasureOfTasks(plan.tasks[t].start, plan.tasks[t].end);
                            }
                            
                        } else {
                            // Create a new assignment
                            let personname = props.people.find(p=>p.id == assignment.id)?.name;
                            if (personname == undefined || personname == null)
                            {
                                personname = "Unknown Person " + unknownindex++;
                            }
                            
                            newassndata.push(
                                {
                                    Person:personname, 
                                    Completed:taskstatus == TaskStatus.Completed && a==0 ? 1:0, 
                                    CompletedAlreadyassigned:taskstatus == TaskStatus.Completed  && a>0?1:0, 
                                    InProgress:taskstatus == TaskStatus.InProgress && a==0 ?1:0, 
                                    InProgressAlreadyassigned:taskstatus == TaskStatus.InProgress  && a>0?1:0, 
                                    NotStarted:taskstatus == TaskStatus.NotStarted && a==0 ?1:0, 
                                    NotStartedAlreadyassigned:taskstatus == TaskStatus.NotStarted  && a>0?1:0, 
                                    Late:taskstatus == TaskStatus.Late && a==0?1:0, 
                                    LateAlreadyassigned:taskstatus == TaskStatus.Late  && a>0?1:0, 
                                    Id:assignment.id,
                                    alsoassignedto:[]
                                });
                        
                        }
                    }
                    
                }                
            }
        }
        newassndata = newassndata.sort((a,b)=>{ 
            return (a.Completed+a.InProgress+a.NotStarted+a.Late+a.CompletedAlreadyassigned+a.InProgressAlreadyassigned+a.NotStartedAlreadyassigned+a.LateAlreadyassigned)<(b.Completed+b.InProgress+b.NotStarted+b.Late+b.CompletedAlreadyassigned+b.InProgressAlreadyassigned+b.NotStartedAlreadyassigned+b.LateAlreadyassigned)?1:-1
    });
        //newassndata = newassndata.sort((a,b)=>a.Person.localeCompare(b.Person));   
        log('dashboard assigned people', newassndata, props.dashboardplans);
        setPeopledata(newassndata);
    }, [props.dashboardplans, props.people]);
    const getMeasureOfTasks = (startd:Date, endd:Date):number => 
    {
        if (props.measureTasks == MeasureTasks.Duration)
        {
            return getDurationInDaysExcludingWeekends(startd, endd);
        } else
        {
            return 1;
        }
    }
  return (
    <>
        <Bar
            data={peopledata}
            width={props.width}
            height={props.height}
            keys={[
                'NotStarted',
                'NotStartedAlreadyassigned',
                'InProgress',
                'InProgressAlreadyassigned',
                'Late',
                'LateAlreadyassigned',
                'Completed',
                'CompletedAlreadyassigned'
            ]}
            layout="vertical"
            indexBy="Person"
            margin={{ top: 70, right: 40, bottom: 100, left: 100 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={labelcolors}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
                ,
                {
                    id: 'completed',
                    type: 'patternLines',
                    background: '#97e3d5',
                    color: '#61cdbb',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                },
                {
                    id: 'notstarted',
                    type: 'patternLines',
                    background: '#e5f5e0',
                    color: '#e5f5e0',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            fill={[
                {
                    match: {
                        id: 'NotStartedOld'
                    },
                    id: 'notstarted'
                },
                {
                    match: {
                        id: 'CompletedOld'
                    },
                    id: 'completed'
                }
            ]}
        
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 45,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 32,
                truncateTickAt: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'No. Tasks',
                legendPosition: 'middle',
                legendOffset: -40,
                truncateTickAt: 0
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            
            role="application"
            ariaLabel="People Tasks"
            barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
            borderRadius={4}
            
            tooltip={({
                id,
                value,
                color,
                indexValue,
                data,
            }) => 
                { 
                    log('tooltup', (data).alsoassignedto); 
                    
                    return <div style={{
                            padding: 12,
                            color,
                            background: '#FFFFFF'
                        }}>
                            <span>Look, I'm custom :)</span>
                            <br />
                            <strong>
                                {id}: {value} {indexValue} {data.Person}
                                also assigned to
                                {data.alsoassignedto.map((a:any)=>{return a + " "} )}
                                
                            </strong>
                        </div>;
                }
            } 
        />
    </>
  );
}
