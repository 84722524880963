import React, { useEffect, useState } from 'react';
import HeatmapComponent from "./HeatmapComponent";
import { GanttPlan, Person } from '../../ganttmods/publictypes';
import { getDateString, getDaysBetweenDates, getOrdinalSuffix, getWeekNumber } from '../helper';
import './HeatmapContainer.css';
import HeatmapDropdown from './HeatmapDropdown';


export enum ViewMode {
  Year = "year",
  Month = "month",
  Week = "week",
  Day = "day",
}


// HeatmapContainer Component
interface HeatmapContainerProps {
  ganttPlans: GanttPlan[];
  view?: ViewMode;
  theme: "light" | "dark";
  height?: number;
  width?: number;
  people:Person[]
}
interface Dictionary<T> {
  [key: string]: T;
}
export const unassignedtaskname = "Unassigned Tasks";
const HeatmapContainer: React.FC<HeatmapContainerProps> = ({ ganttPlans, people, view, theme, height=300, width=800 }) => {
  const [viewMode, setViewMode] = useState(view || ViewMode.Month);
  const [bottomHeader, setBottomHeader] = useState<string[]>([]); // xLabels
  const [bottomHeaderLong, setBottomHeaderLong] = useState<string[]>([]); // xLabelsLong
  const [topHeader, setTopHeader] = useState<string[]>([]); // secondHeaderLabels
  const [yLabels, setYLabels] = useState<string[]>([]); // yLabels
  const [data, setData] = useState<number[][]>([]); // data
  const [gscrollto, setgScrollto] = useState<number | undefined>(undefined); // data
  const [maxassignedvalue, setMaxAssignedValue] = useState<number>(0); // data
  

  // Transform GanttPlan data to HeatmapComponent data format
  const transformGanttPlansToHeatmapData = (ganttPlans: GanttPlan[], viewMode: ViewMode) => {
    const yLabelsSet = new Set<string>();
    const dateSet = new Set<string>();
    const dateSetLabels: Dictionary<Date> = {};
    const taskCounts: { [key: string]: { [key: string]: number } } = {};
    let currentmaxassignedvalue = 0;
    ganttPlans.forEach((plan) => {
      plan.tasks?.forEach((task) => {
        task.assignments.forEach((assignment) => {
          let username = people.find(person => person.id == assignment.id)?.name;
            if (username == undefined)
            {
              username = "Unknown";
            }
          yLabelsSet.add(username);
        });
        
        let taskstart = new Date(task.start);
        let taskend = new Date(task.end);
        if (task.start == null)
        {
          taskstart = new Date();
        }
        if (task.end == null)
        {
          taskend = new Date();
        }
        let currentDate = new Date(taskstart);
        while (currentDate <= taskend) {
          const dateStr = getDateString(currentDate, viewMode, true);
          dateSet.add(dateStr);
          dateSetLabels[dateStr] = new Date(currentDate);

          if (task.assignments == undefined || task.assignments == null ||task.assignments.length === 0) {
            
            if (!taskCounts[unassignedtaskname]) {
              taskCounts[unassignedtaskname] = {};
              yLabelsSet.add(unassignedtaskname);
            }
            taskCounts[unassignedtaskname][dateStr] = (taskCounts[unassignedtaskname][dateStr] || 0) + 1;
          }
          task.assignments.forEach((assignment) => {
            
            let username = people.find(person => person.id == assignment.id)?.name;
            if (username == undefined)
            {
              username = "Unknown";
            }
            if (!taskCounts[username]) {
              taskCounts[username] = {};
            }
            taskCounts[username][dateStr] =
              (taskCounts[username][dateStr] || 0) + 1;
              currentmaxassignedvalue = currentmaxassignedvalue>taskCounts[username][dateStr]?currentmaxassignedvalue:taskCounts[username][dateStr];
          });

          switch (viewMode) {
            case "year":
              currentDate.setFullYear(currentDate.getFullYear() + 1);
              break;
            case "month":
              currentDate.setMonth(currentDate.getMonth() + 1);
              break;
            case "week":
              currentDate.setDate(currentDate.getDate() + 7);
              break;
            case "day":
              currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);;
              
              break;
          }
        }
      });
    });
    setMaxAssignedValue(currentmaxassignedvalue);
    const mindate = new Date(Math.min(...Array.from(dateSet).map(date => new Date(date).getTime())));
    const maxdate = new Date(Math.max(...Array.from(dateSet).map(date => new Date(date).getTime()))); 
    let scrollto = undefined;
    switch (viewMode) {
      case "year":
        scrollto = (new Date().getFullYear() - mindate.getFullYear())*40;
        break;
      case "month":
        scrollto = (new Date().getFullYear() - mindate.getFullYear())*40*12 + (new Date().getMonth() - mindate.getMonth())*40;
        break;
      case "week":
        //not implemented
        break;
      case "day":
        scrollto = getDaysBetweenDates(mindate, new Date())*40;
        
        break;
    }
    
    
    let currentDate = mindate;
    while (currentDate <= maxdate) 
    {
      if (dateSet.has(getDateString(currentDate, viewMode, true))) 
      {
      } else 
      {
        const dateStr = getDateString(currentDate, viewMode, true);
        dateSet.add(dateStr);
        dateSetLabels[dateStr] = new Date(currentDate);
      }
      switch (viewMode) {
        case "year":
          currentDate.setFullYear(currentDate.getFullYear() + 1);
          break;
        case "month":
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
        case "week":
          currentDate.setDate(currentDate.getDate() + 7);
          break;
        case "day":
          currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);;
          
          break;
      }
    }

    const sortedDates = Array.from(dateSet).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    const bottomHeader = sortedDates.map(date => {
      const parsedDate = new Date(dateSetLabels[date]);
      switch (viewMode) {
        case "day":
          return `${parsedDate.getDate().toString().padStart(2, '0')} ${parsedDate.toLocaleString('default', { weekday: 'short' })}`;
        case "week":
          const weekNumber = getWeekNumber(parsedDate)[1];
          return `W${weekNumber.toString().padStart(2, "0")}`;
        case "month":
          return `${parsedDate.toLocaleString('default', { month: 'short' })}`
        case "year":
          return `${parsedDate.getFullYear()}`;
        default:
          return "";
      }
    });;
    const bottomHeaderLong = sortedDates.map(date => {
      const parsedDate = new Date(dateSetLabels[date]);
      switch (viewMode) {
        case "day":
          return `${parsedDate.toLocaleString('default', { weekday: 'long' })} ${parsedDate.getDate().toString()}${getOrdinalSuffix(parsedDate.getDate())} (${parsedDate.toLocaleString('default', { month: 'long' })})`;
        case "week":
          const weekNumber = getWeekNumber(parsedDate)[1];
          return `Week ${weekNumber.toString().padStart(2, "0")}`;
        case "month":
          return `${parsedDate.toLocaleString('default', { month: 'long' })}`
        case "year":
          return `${parsedDate.getFullYear()}`;
        default:
          return "";
      }
    });;
    let yLabels = Array.from(yLabelsSet);
    yLabels = yLabels.sort((a, b)=>a==unassignedtaskname?1:b==unassignedtaskname?-1:a.localeCompare(b)); 
    const data = yLabels.map((label) =>
      sortedDates.map((date) => taskCounts[label]?.[date] || 0)
    );
    
    // Create topHeader based on viewMode
    const topHeader = sortedDates.map(date => {
      const parsedDate = new Date(dateSetLabels[date]);
      switch (viewMode) {
        case "day":
          return `${parsedDate.getFullYear()}-${parsedDate.toLocaleString('default', { month: 'short' })}`;
        case "week":
          return `${parsedDate.getFullYear()}`;
        case "month":
        case "year":
          return `${parsedDate.getFullYear()}`;
        default:
          return "";
      }
    });

    return { bottomHeader, bottomHeaderLong, topHeader, yLabels, data, scrollto };
  };

  
  useEffect(() => {
    if (ganttPlans == undefined || people == undefined || ganttPlans.length == 0 || people.length == 0)
    {
        return;
    }
    const { bottomHeader, bottomHeaderLong, topHeader, yLabels, data, scrollto } = transformGanttPlansToHeatmapData(ganttPlans, viewMode);
    setBottomHeader(bottomHeader);
    setBottomHeaderLong(bottomHeaderLong);
    setTopHeader(topHeader);
    setYLabels(yLabels);
    setData(data);
    setgScrollto(scrollto);
  }, [ganttPlans, people, viewMode]);



  return (
    <div className={"rootheatmap " + (theme == "light"?"lighttheme":"darktheme")}>
      
      <HeatmapDropdown view={viewMode} onViewChange={setViewMode} width={width} />
      <HeatmapComponent
        xLabels={bottomHeader}
        xLabelsLong={bottomHeaderLong}
        secondHeaderLabels={topHeader}
        yLabels={yLabels}
        data={data}
        maxColor="244, 144, 151" // Custom color (blue)
        minColor="85, 214, 194" // Custom color (light gray)
        maxValue={maxassignedvalue}
        width={width + 'px'}
        height={height + 'px'}
        scrollto={gscrollto}
      />
    </div>
  );
};

export default HeatmapContainer;
